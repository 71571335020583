import { contacts } from "../data";

const ContactSection = () => {
    return (
        <section id="contact" className="section bg-secondary min-h-[80vh]">
            <div className="container mx-auto">
                <div className="flex flex-col items-center text-center">
                    <h2 className="section-title before:content-contact 
                    relative before:absolute before:opacity-40 before:-top-7
                     before:-left-40 before:hidden before:lg:block">
                        Contact me
                    </h2>
                </div>
                <div className="flex flex-col lg:gap-x-8 lg:flex-row">
                    <div className="flex flex-1 flex-col gap-y-8 items-center
                     mb-12 lg:mb-0 lg:pt-2">
                        {
                            contacts.map((item, index) => {
                                const { icon, title, description } = item;

                                return (
                                    <div className="flex flex-col lg:flex-row
                                     lg:gap-x-2 items-center" key={index}>
                                        <div className="text-accent rounded-sm w-7 h-7 
                                        flex justify-center mt-2 mb-4 lg:mb-0 text-2xl">
                                            {icon}
                                        </div>
                                        <h4 className="text-accent font-body text-xl mb-1">{title}</h4>
                                        <p className="text-white font-normal">{description}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactSection