import { useNavigate } from "react-router-dom";
import { ProjectType } from "../types";

type ProjectProps = {
    item: ProjectType;
}

const Project = ({ item }: ProjectProps) => {
    const navigate = useNavigate();
    return (
        <div key={item.id} onClick={() => { navigate(`/projects/${item.id}`) }}
            className='flex flex-col items-center text-center cursor-pointer'>
            <div className="mb-8">
                <img className="rounded-2xl" src={item.image} alt="" />
            </div>
            <h3 className="text-xl font-semibold capitalize mb-10">{item.name}</h3>
        </div>
    )
}

export default Project