import ReactIcon from '../assets/images/skills/reactjs.png';
const FooterSection = () => {
    return (
        <footer className="bg-tertiary py-12">
            <div className="container mx-auto">
                <div className="flex flex-col lg:flex-row space-y-6
                lg:space-y-0 items-center justify-center">
                    <p>
                        &copy; Debarghya Chakraborty {`${new Date().getFullYear()}`}. All rights reserved.
                        <br />
                        Built with <img style={{'display':'inline','height':'25px'}} src={ReactIcon} alt="" />
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default FooterSection