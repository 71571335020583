import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutSection from './components/AboutSection';
import BackToTopButton from './components/BackToTopButton';
import ContactSection from './components/ContactSection';
import FooterSection from './components/FooterSection';
import HeaderSection from './components/HeaderSection';
import HeroSection from './components/HeroSection';
import PortfolioSection from './components/PortfolioSection';
import SkillsSection from './components/SkillsSection';
import ProjectDetails from './pages/ProjectDetails';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={[
            <HeaderSection />,
            <HeroSection />,
            <AboutSection />,
            <SkillsSection />,
            <PortfolioSection />,
            <ContactSection />,
            <FooterSection />,
            <BackToTopButton />
          ]} />
          <Route path='/projects/:id' element={<ProjectDetails />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
