//  icons
import { FiMail, FiMapPin } from 'react-icons/fi';
import { ImWhatsapp } from 'react-icons/im';
import { IoMdCall } from 'react-icons/io';

// projects images
import Project1 from './assets/images/projects/travel-booking-website.png';
import Project2 from './assets/images/projects/e-commerce-website.png';
import Project3 from './assets/images/projects/car-rental-website.png';
import Project4 from './assets/images/projects/movie-streaming-website.png';
import Project5 from './assets/images/projects/anime-website.png';

// skills images
import SkillImage1 from './assets/images/skills/ts.png';
import SkillImage2 from './assets/images/skills/reactjs.png';
import SkillImage3 from './assets/images/skills/redux.png';
import SkillImage4 from './assets/images/skills/nextjs.png';
import SkillImage5 from './assets/images/skills/graphql.png';
import SkillImage6 from './assets/images/skills/rtl.png';
import SkillImage7 from './assets/images/skills/react-native.png';
import SkillImage8 from './assets/images/skills/nodejs.png';
import SkillImage9 from './assets/images/skills/mongodb.png';
import SkillImage10 from './assets/images/skills/jenkins.png';
import SkillImage11 from './assets/images/skills/docker.png';
import SkillImage12 from './assets/images/skills/aws.png';

import { ProjectType } from './types';


// navigation
export const navigation = [
    {
        name: 'home',
        href: 'home',
    },
    {
        name: 'about',
        href: 'about',
    },
    {
        name: 'portfolio',
        href: 'portfolio',
    },
    {
        name: 'contact',
        href: 'contact',
    },
];

// projects
export const projectsData: ProjectType[] = [
    {
        id: '1',
        image: Project1,
        name: 'travel booking website',
        technology: 'TypeScript - React - Node - Express - MongoDB',
        description:
            `• This is a website where you can book your travel.
        <br/> • This is a full stack application built using the MERN stack.
        <br/> • The UI is built with React and plain css.
        <br/> • useReducer hook has been used to manage the state.
        <br/> • For the backend, the server is built with Node and Express
        <br/> &nbsp;&nbsp; and MongoDB has been used as the database.
        <br/> • Login credentials - username: <em>debarghya25</em> password: <em>123456</em>`,
        url: 'https://travel-booking-website-client.vercel.app'
    },
    {
        id: '2',
        image: Project2,
        name: 'e-commerce website',
        technology: 'TypeScript - React - Node - Express - Firebase',
        description:
            `• This is a website where you can buy products online.
        <br/> • This is a full stack application built with React and Node.
        <br/> • The UI is built with React and plain css.
        <br/> • useReducer hook has been used to manage the state.
        <br/> • For the backend, the server is built with Node and Express
        <br/> &nbsp;&nbsp; and Firebase has been used as the database and also for authentication.
        <br/> • Stripe has been used for payment gateway integration.
        <br/> • Login credentials - username: <em>debarghya@email.com</em> password: <em>123456</em>`,
        url: 'https://e-commerce-website-client.vercel.app'
    },
    {
        id: '3',
        image: Project3,
        name: 'car rental website',
        technology: 'TypeScript - React - Nest - GraphQL - TypeORM - MySQL ',
        description:
            `• This is a website where you can rent and lend cars.
        <br/> • This is a full stack application built with React and Nest.
        <br/> • The UI is built with React and styled with styled components and tailwindcss.
        <br/> • The state of the application is managed with Redux Toolkit.
        <br/> • For the backend, the server is built with Nest and GraphQL
        <br/> &nbsp;&nbsp; and MySQL has been used as the database, managed with TypeORM.`,
        url: 'https://car-rental-website-client.vercel.app'
    },
    {
        id: '4',
        image: Project4,
        name: 'movie streaming website',
        technology: 'TypeScript - Next - Firebase',
        description:
            `• This is a website where you can watch movies and shows.
        <br/> • This is a full stack application built with Next framework of React.
        <br/> • The UI is built with React and styled with tailwindcss.
        <br/> • For the backend, the data is fetched from a third party api
        <br/> &nbsp;&nbsp; and Firebase has been used for authentication.`,
        url: 'https://movie-streaming-website-43b5g6b39-debarghya23.vercel.app'
    },
    {
        id: '5',
        image: Project5,
        name: 'anime watching website',
        technology: 'TypeScript - React',
        description:
            `• This is a website where you can watch your favourite animes.
        <br/> • This is a frontend application built with React.
        <br/> • The UI is built with React, typescript and styled with Material UI.
        <br/> • For the backend, the data is fetched from a third party api.`,
        url: 'https://react-anime-website.vercel.app/'
    },
];

// skill
export const skills = [
    {
        image: SkillImage1,
    },
    {
        image: SkillImage2,
    },
    {
        image: SkillImage3,
    },
    {
        image: SkillImage4,
    },
    {
        image: SkillImage5,
    },
    {
        image: SkillImage6,
    },
    {
        image: SkillImage7,
    },
    {
        image: SkillImage8,
    },
    {
        image: SkillImage9,
    },
    {
        image: SkillImage10,
    },
    {
        image: SkillImage11,
    },
    {
        image: SkillImage12,
    },
];

// contact
export const contacts = [
    {
        icon: <FiMail />,
        title: 'Send an email :',
        description: 'developerdebarghya@gmail.com',
    },
    {
        icon: <IoMdCall />,
        title: 'Call me :',
        description: '+91 - 9748340807',
    },
    {
        icon: <ImWhatsapp />,
        title: 'Send a message on WhatsApp :',
        description: '+91 - 9748340807',
    },
    {
        icon: <FiMapPin />,
        title: 'Address :',
        description: 'Kolkata, West Bengal, India',
    },
];
