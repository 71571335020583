import { Link } from 'react-scroll';
import AboutImage from '../assets/images/programmer.png';

const AboutSection = () => {
    return (
        <section id='about' className='section bg-secondary'>
            <div className="container mx-auto">
                <div className="flex flex-col xl:flex-row sm:gap-[96px]">
                    <img className='object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl'
                        src={AboutImage} alt="" />
                    <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
                        <div className="flex flex-col">
                            <h2 className="text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3
                            before:content-about relative before:absolute before:opacity-40
                            before:-top-[2rem] before:hidden before:lg:block">
                                Debarghya Chakraborty
                            </h2>
                            <p className='mb-4 text-accent'>Fullstack developer</p>
                            <hr className='mb-8 opacity-5' />
                            <p className='mb-8'>
                                As a self-taught Fullstack MERN Developer, I excel in crafting dynamic web apps with React and mobile apps with React Native.
                                I use Next.js for enhanced SEO and server-side rendering, streamline backend processes using Node.js and Express.
                                My expertise extends to deploying robust solutions on AWS cloud using Docker and Jenkins as part of CI/CD. 
                            </p>
                        </div>
                        <Link to={'contact'} smooth={true}
                            duration={800} offset={-70}
                            className='btn btn-md bg-accent hover:bg-accent-hover transition-all cursor-pointer'>
                            Contact me
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection