import { MenuAlt4Icon, XIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-scroll';
import { navigation } from '../data';

const NavbarMobile = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const circleVariants = {
        hidden: {
            scale: 0
        },
        visible: {
            scale: 180,
            transition: {
                type: 'spring',
                stiffness: 160,
                damping: 60
            }
        }
    }

    const ulVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                delay: 0.1
            }
        }
    }

    return (
        <nav className="relative">
            <div onClick={() => setIsMenuOpen(true)} className="cursor-pointer text-white">
                <MenuAlt4Icon className='w-8 h-8' />
            </div>

            <motion.div variants={circleVariants} initial='hidden'
                animate={isMenuOpen ? 'visible' : 'hidden'}
                className='w-4 h-4 rounded-full bg-accent fixed top-0 right-0'>
            </motion.div>

            <motion.ul variants={ulVariants} initial='hidden'
                animate={isMenuOpen ? 'visible' : 'hidden'}
                className={`${isMenuOpen ? 'right-0' : '-right-full'} 
                fixed top-0 bottom-0 w-full flex flex-col justify-center
                items-center transition-all duration-300 overflow-hidden`}>
                <div onClick={() => setIsMenuOpen(false)} className="cursor-pointer absolute top-8 right-8">
                    <XIcon className='w-8 h-8' />
                </div>
                {
                    navigation.map((item, index) => {
                        return (
                            <li className="mb-8" key={index}>
                                <Link onClick={() => setIsMenuOpen(false)} to={item.href} smooth={true}
                                    duration={800} offset={-70}
                                    className='text-xl cursor-pointer capitalize hover:text-black'>
                                    {item.name}
                                </Link>
                            </li>
                        )
                    })
                }
            </motion.ul>
        </nav>
    )
}

export default NavbarMobile