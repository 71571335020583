import { skills } from "../data"

const SkillsSection = () => {
    return (
        <section id='about' className="bg-tertiary py-6">
            <h2 className="section-title before:content-about relative
                    before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4
                    before:hidden before:lg:block">
                Technologies I work with
            </h2>
            <div className="container mx-auto">
                <div className="grid grid-cols-12 md:grid-flow-row">
                    {
                        skills.map((item, index) => {
                            return (
                                <div key={index} className='flex items-center justify-between'>
                                    <img src={item.image} alt="" />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default SkillsSection