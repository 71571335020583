import { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import Logo from '../assets/images/Logo.jpg';
import Navbar from './Navbar';
import NavbarMobile from './NavbarMobile';

const HeaderSection = () => {
    const [background, setBackground] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            return window.scrollY > 50 ? setBackground(true) : setBackground(false);
        });
    })

    return (
        <header className={`${background ? 'bg-tertiary h-20' : 'h-24'}
        flex items-center fixed top-0 w-full text-white z-10 transition-all duration-300`}>
            <div className="container mx-auto h-full flex items-center justify-between">
                <Link to={'home'} href="#home" smooth={true}
                    duration={800} offset={-70}>
                    <img src={Logo} alt="" />
                </Link>
                <div className="hidden lg:block">
                    <Navbar />
                </div>
                <div className="lg:hidden">
                    <NavbarMobile />
                </div>
            </div>
        </header>
    )
}

export default HeaderSection
