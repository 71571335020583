import { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from "swiper/react";
import { projectsData } from "../data";
import '../swiper.css';
import { ProjectType } from "../types";
import Project from "./Project";

const ProjectsSection = () => {
    const [projects, setProjects] = useState<ProjectType[]>([]);

    useEffect(() => {
        setProjects(projectsData);
    }, []);

    return (
        <section id='portfolio' tabIndex={2}>
            <Swiper initialSlide={3} slidesPerView={3} navigation spaceBetween={20} loop={true}
                pagination={{ clickable: true }} modules={[Pagination, Navigation]}
                className='mySwiper'>
                {
                    projects.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <Project item={item} />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </section>
    )
}

export default ProjectsSection