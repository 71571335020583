import ProfileImage from '../assets/images/IMG_20190509_115627-copy.jpg';

const HeroSection = () => {
    return (
        <section id='home' className='h-[100vh] lg:h-[100vh] flex items-center
         bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat
          py-32 lg:py-0 overflow-hidden'>
            <div className="container mx-auto h-full">
                <div className="flex items-center flex-col md:flex-row h-full pt-8">
                    <div className="flex flex-1 flex-col items-center lg:items-center">
                        <h1 className="text-4xl leading-[44px] md:text-5xl md:leading-tight
                         lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px]">
                            Hi, <br /> I am Debarghya
                        </h1>
                        <p className='pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px]
                         text-3xl text-center'>
                            Fullstack developer
                        </p>
                    </div>
                    <div className="md:flex flex-1 justify-end items-center h-full w-full">
                        <img className='object-cover w-[566px] mt-4 md:mt-0 md:mx-auto lg:mx-0 rounded-2xl' src={ProfileImage} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroSection