import ReactHtmlParser from 'react-html-parser';
import { useParams } from "react-router-dom";
import { projectsData } from "../data";

const ProjectDetails = () => {
    const { id } = useParams();
    const project = projectsData.find(p => p.id === id);
    return (
        <div className="flex flex-col lg:h-[100vh] px-[10px] bg-secondary items-center">
            <h2 className="section-title py-[10px] capitalize text-accent font-bold">
                {project?.name}
            </h2>
            <img className="rounded-lg lg:h-[500px] lg:w-auto w-[75vw] sm:w-[75vw]" src={project?.image} alt="" />
            <h3 className="text-lg font-bold mt-2" style={{ color: '#a55d22' }}>Technologies used</h3>
            <p className="text-lg text-center my-1">{project?.technology}</p>
            <h3 className="text-lg font-bold mt-2" style={{ color: '#a55d22' }}>Description</h3>
            <p className="text-lg text-left my-1">{ReactHtmlParser(project?.description!)}</p>
            <a href={project?.url} target="_blank" rel="noreferrer" className='my-4 btn btn-md bg-accent
             hover:bg-accent-hover transition-all cursor-pointer'>
                View live demo
            </a>
        </div>
    )
}

export default ProjectDetails