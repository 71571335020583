import { ChevronUpIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";

const BackToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            return window.scrollY > 600 ? setShowButton(true) : setShowButton(false);
        })
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    return (
        showButton ?
            (
                <button onClick={() => scrollToTop()}
                className="bg-accent w-12 h-12 hover:bg-accent-hover
                text-white rounded-full fixed right-24 bottom-24 cursor-pointer
                flex justify-center items-center transition-all">
                    <ChevronUpIcon className="w-6 h-6" />
                </button>
            ) : null
    )
}

export default BackToTopButton